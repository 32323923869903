/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 8, 2021 */



@font-face {
  font-family: 'playfair_displaybold';
  src: url('../fonts/playfairdisplay-bold-webfont.eot');
  src: url('../fonts/playfairdisplay-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
  url('../fonts/playfairdisplay-bold-webfont.woff') format('woff'),
  url('../fonts/playfairdisplay-bold-webfont.ttf') format('truetype'),
  url('../fonts/playfairdisplay-bold-webfont.svg#playfair_displaybold') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'playfair_displayregular';
  src: url('../fonts/playfairdisplay-regular-webfont.eot');
  src: url('../fonts/playfairdisplay-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
  url('../fonts/playfairdisplay-regular-webfont.woff') format('woff'),
  url('../fonts/playfairdisplay-regular-webfont.ttf') format('truetype'),
  url('../fonts/playfairdisplay-regular-webfont.svg#playfair_displayregular') format('svg');
  font-weight: normal;
  font-style: normal;

}