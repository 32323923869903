.tips {
  &-block {
    padding: rem(55px 0 140px);
    background: url(../img/contact-bg.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
  }
  &-item {
    margin: rem(0 0 13px);
    border:1px solid cl(border);
    background: white;
    &:hover {
      border-color:cl(secondary);
    }
    &.active {
      border-color:cl(secondary);
      box-shadow: rem(0 19px 40px cl(black, .16));
      .tips-item-content {
        display: block;
      }
      .tips-item-button {
        .arrow {
          transform: rotate(-180deg);
        }
      }
    }
    &-button {
      display: block;
      width:100%;
      text-align: left;
      font-size: rem(24px);
      line-height: rem(28px);
      color:cl(secondary);
      font-family: $fontTitle;
      position: relative;
      border:0;
      background: none;
      padding: rem(24px 50px 24px 77px);
      &:focus {
        outline: none;
        color:cl(primary);
      }
      &:hover {
        background: cl(secondary, .05);
      }
      @include media-breakpoint-up(xs) {
        padding: rem(24px 40px 24px 65px);
      }
      .icon {
        position: absolute;
        left:rem(26px);
        top:rem(22px);
        width:rem(32px);
        height:rem(32px);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        @include media-breakpoint-up(xs) {
          left:rem(20px);
        }
        svg {
          width:rem(28px);
          height:rem(28px);
          fill: cl(primary);
        }
      }
      .arrow {
        position: absolute;
        right:rem(20px);
        top:rem(20px);
        transition: all .3s linear;
        svg {
          width:rem(16px);
          height:rem(16px);
          fill: cl(primary);
        }
      }
    }
    &-content {
      padding: rem(10px 40px 10px 77px);
      font-size: rem(18px);
      line-height: rem(28px);
      display: none;
      @include media-breakpoint-up(xs) {
        padding: rem(10px 20px 10px 26px);
      }
      h2, h3, h4 {
        font-family: $fontTitle;
        font-size: rem(24px);
        color:cl(primary);
      }
    }
  }
}