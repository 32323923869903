.contact {
  &--background {
    background: url(../img/contact-bg.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
    position: relative;
    padding-bottom: rem(45px);
  }
  &-box {
    background: white;
    box-shadow: rem(0 19px 40px cl(black, .16));
    padding: rem(30px 45px 45px);
    font-size: rem(16px);
    line-height: rem(26px);
    position: relative;
    @include media-breakpoint-down(xs) {
      padding: 2rem;
    }
    &-logo {
      position: absolute;
      right:rem(60px);
      bottom: rem(38px);
      width:rem(395px);
    }
    &-item {
      position: relative;
      padding: rem(0 0 15px 30px);
      svg {
        position: absolute;
        top:rem(2px);
        left:0;
        width:rem(20px);
        height: rem(20px);
        fill:cl(secondary);
      }
      h2 {
        font-family: $fontTitle;
        font-weight: normal;
        color:cl(secondary);
        font-size: rem(20px);
        margin: rem(0 0 15px);
      }
    }
    &-cards {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      img {
        width:rem(35px);
        height: auto;
        margin: rem(0 3px 3px 0);
        border:1px solid #aaa;
        border-radius: rem(3px);
      }
    }
  }
}