.about {
  &-block {
    overflow: hidden;
    padding: rem(10px 0 150px);
    font-size: rem(18px);
    line-height: rem(30px);
    background: url(../img/about-bg.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
    @include media-breakpoint-down(md) {
      padding-bottom: 14rem;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 6rem;
    }
    &-text {
      padding: rem(35px 0 0);
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }
    .title {
      color:cl(primary);
    }
    &-image {
      @include media-breakpoint-down(xs) {
        margin: 0 0 2rem;
      }
      img {
        width:100%;
        height: auto;
      }
    }
  }
  &-partners {
    overflow: hidden;
    font-size: rem(18px);
    line-height: rem(30px);
    padding: rem(60px 0);
    color:white;
    background: cl(primary) url(../img/insurance-bg.jpg) no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    &-text {

    }
    .title {
      color:white;
    }
    a {
      color:white;
    }
    &-image {
      @include media-breakpoint-down(xs) {
        margin: 0 0 2rem;
      }
      img {
        width:100%;
        height: auto;
      }
    }
  }
  &-team {
    padding: rem(45px 0 65px);
    @include media-breakpoint-down(xs) {
      padding: rem(45px 0);
    }
    &--background {
      background: url(../img/contact-bg.jpg) no-repeat 50% 100%;
      background-size: 100% auto;
      padding-bottom: rem(110px);
      @include media-breakpoint-down(xs) {
        padding-bottom: rem(60px);
      }
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: rem(0 0 30px);
      &:nth-child(even) {
        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      &.active {
        .about-team-item-text-inner {
          height: auto;
          -webkit-line-clamp: inherit;
        }
        .about-team-item-more {
          color:cl(primary);
          em {
            display: inline;
          }
          span {
            display: none;
          }
          i {
            transform: rotate(-180deg);
          }
        }
      }
      &-text {
        background: white;
        width:100%;
        box-shadow: rem(0 19px 40px cl(black, .16));
        font-size: rem(18px);
        line-height: rem(28px);
        padding: rem(32px 55px);
        @include media-breakpoint-down(xs) {
          padding: 2rem;
        }
        .title {
          margin: rem(0 0 10px);
        }
        p {
          margin: 0;
        }
        &-inner {
          height: rem(168px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          margin: rem(0 0 20px);
          transition: all .3s ease;
        }
      }
      &-more {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        color:cl(secondary);
        letter-spacing: rem(1px);
        font-size: rem(16px);
        font-weight: 700;
        transition: all .3s ease;
        &:hover {
          text-decoration: none;
        }
        i {
          //transition: all .3s ease;
        }
        em {
          display: none;
          font-style: normal;
        }
      }
      &-image{
        flex-shrink: 0;
        width:rem(520px);
        padding-top: 30%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        @include media-breakpoint-down(md) {
          width:25rem;
        }
        @include media-breakpoint-down(xs) {
          width:100%;
          padding-top: 70%;
        }
      }
    }
  }
}