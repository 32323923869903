.faq {
  .card {
    box-shadow:none;
    border-radius:0;
    background: white;
    position: relative;
    border: 0;
    margin: rem(0 0 20px);
    .card-header {
      background: none;
      border: 0;
      padding: 0;
      button {
        font-size: rem(26px);
        line-height: rem(30px);
        font-family: $fontTitle;
        font-weight: normal;
        color:cl(secondary);
        width:100%;
        display: block;
        border: 1px solid cl(secondary);
        border-bottom: 0;
        background: white;
        padding: rem(20px 50px 20px 40px);
        position: relative;
        text-align: left;
        z-index: 2;
        &:focus {
          outline: none;
        }
        &.collapsed {
          border: 1px solid #e1e1e1;
          .arrow {
            transform: rotate(0);
          }
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        .arrow {
          position: absolute;
          right:rem(28px);
          top:rem(20px);
          transform: rotate(-180deg);
          transition: all .3s ease;
          svg {
            width:rem(18px);
            height: rem(18px);
            fill:cl(primary);
          }
        }
      }
    }
    .card-body {
      padding: rem(15px 50px 15px 40px);
      font-size: rem(18px);
      line-height: rem(38px);
      border: 1px solid cl(secondary);
      border-top:0;
      box-shadow: rem(0 19px 40px cl(black, .16));
      p {
        margin: 0 0 1rem;
      }
      ul {
        list-style: none;
        li {
          position: relative;
          padding: rem(0 0 0 15px);
          &:before {
            content: "";
            position: absolute;
            left:0;
            top:rem(15px);
            width:rem(5px);
            height: rem(5px);
            background: cl(secondary);
            border-radius: 50%;
          }
        }
      }
    }
  }
}