.upcoming {
  background-color: white;
  background-size: 100% auto;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  padding: rem(80px 40px 0);
  padding-bottom: 19%;
  position: relative;
  @include media-breakpoint-down(xs) {
    padding: 2rem 2rem 25%
  }
  &-list {
    display: flex;
    justify-content: space-between;
    gap:rem(47px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @include media-breakpoint-down(xs) {
      gap:2.5rem;
    }
  }
  &-item {
    border:1px solid cl(secondary);
    height: rem(160px);
    font-size: rem(26px);
    line-height: rem(26px);
    padding: rem(20px 30px);
    letter-spacing: rem(2px);
    font-family: $fontTitle;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    color:cl(secondary);
    position: relative;
    width:100%;
    box-shadow: rem(0 19px 40px cl(black,.16));
    @include media-breakpoint-down(lg) {
      width:30%;
    }
    @include media-breakpoint-down(md) {
      width:40%;
    }
    @include media-breakpoint-down(xs) {
      width:100%;
      font-size: rem(22px);
      line-height: rem(24px);
      height: auto;
      padding: rem(20px 30px 40px);
    }
    &:hover {
      text-decoration: none;
      color:white;
      background: radial-gradient(ellipse at center, #005aa4 0%, #002965 100%);
      em {
        background: cl(primary);
      }
    }
    em {
      position: absolute;
      left:10%;
      width:80%;
      bottom: rem(-21px);
      background: cl(secondary);
      height: rem(40px);
      border-radius: rem(20px);
      font-size: rem(18px);
      color:white;
      text-transform: none;
      letter-spacing: rem(1px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-logo {
    @extend .pos-centerX;
    width:rem(662px);
    height: auto;
    bottom:15%;
    @include media-breakpoint-down(lg) {
      width: 26.375rem;
      bottom: 8%;
    }
    @include media-breakpoint-down(md) {
      width: 18.375rem;
      bottom: 5%;
    }
    @include media-breakpoint-down(xs) {
      width: 9.375rem;
      bottom: 3%;
    }
  }
}