.newsletter {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: rem(40px 160px);
  position: relative;
  height: rem(600px);
  color:white;
  font-size: rem(18px);
  line-height: rem(30px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(lg) {
    padding: rem(40px);
  }
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
  }
  @include media-breakpoint-down(xs) {
    padding:3rem 2rem;
    height: auto;
  }
  &:before {
    content: "";
    @extend .full;
    background: cl(black, .28);
    backdrop-filter: blur(1px);
  }
  &-inner {
    position: relative;
    z-index: 2;
  }
  h2 {
    text-transform: uppercase;
    font-size: rem(24px);
    letter-spacing: rem(1px);
    font-weight: 700;
    margin: rem(0 0 20px);
    @include media-breakpoint-down(xs) {
      font-size: rem(20px);
    }
  }
  h3 {
    font-size: rem(50px);
    line-height: rem(70px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    @include media-breakpoint-down(xs) {
      font-size: rem(30px);
      line-height: rem(40px);
    }
  }
}