.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh;
	background: cl(white);
	font-family: $font;
	line-height: 1.1;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 83.125rem;
	max-width: var(--size);
	&--small {
		--size: 800px;
	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}
.title {
	font-family: $fontTitle;
	font-weight: normal;
	color:cl(secondary);
	font-size: rem(30px);
	line-height: rem(50px);
	margin: rem(0 0 25px);
	@include media-breakpoint-down(sm) {
		font-size: rem(26px);
		line-height: rem(40px);
	}
}
.page {
	padding: rem(50px 0 35px);
	&--background {
		background: url(../img/page-bottom.jpg) no-repeat 50% 100%;
		background-size: 100% auto;
		padding-bottom: rem(250px);
		position: relative;
		@include media-breakpoint-down(lg) {
			padding-bottom: 12.625rem;
		}
		@include media-breakpoint-down(xs) {
			padding-bottom: 22%;
		}
	}
	&-logo {
		@extend .pos-centerX;
		width:rem(662px);
		height: auto;
		bottom:rem(86px);
		@include media-breakpoint-down(lg) {
			width: 26.375rem;
			bottom: 4.375rem;
		}
		@include media-breakpoint-down(md) {
			width: 18.375rem;
			bottom: 3rem;
		}
		@include media-breakpoint-down(xs) {
			width: 9.375rem;
			bottom: 1.5rem;
		}
	}
	&-title {
		text-align: center;
		font-size: rem(18px);
		padding: rem(0 0 30px);
		h1 {
			font-family: $fontTitle;
			font-weight: normal;
			text-transform: uppercase;
			color:cl(secondary);
			font-size: rem(50px);
			margin: rem(0 0 20px);
			letter-spacing: rem(2px);
		}
	}
}
@include media-breakpoint-down(lg){
	//.wow {
	//	visibility: visible !important;
	//	-webkit-animation: none !important;
	//	-moz-animation: none !important;
	//	-o-animation: none !important;
	//	-ms-animation: none !important;
	//	animation: none !important;
	//}
}
