.home {
  &-cruise {
    padding: rem(55px 40px 25px);
    @include media-breakpoint-down(xs) {
      padding:3rem 2rem 0;
    }
    h2 {
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      color:cl(secondary);
      font-size: rem(24px);
      letter-spacing: rem(2px);
      margin: rem(0 0 -27px);
      @include media-breakpoint-down(lg) {
        margin: rem(0 0 15px);
      }
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 20px);
      }
    }
    .swiper {
      overflow: visible;
      &-holder {
        overflow: hidden;
        padding: rem(50px 0 25px);
        position: relative;
        @include media-breakpoint-down(xs) {
          padding-bottom: 2rem;
          border-bottom: 1px solid #ddd;
        }
      }
      &-all {
        position: absolute;
        right:0;
        top:0;
        text-transform: uppercase;
        color:cl(primary);
        font-size: rem(15px);
        font-weight: 700;
        letter-spacing: rem(1px);
        @include media-breakpoint-down(xs) {
          position: static;
          text-align: center;
          margin: 3rem 0 0;
        }
      }
      &-controls {
        position: absolute;
        left:0;
        top:rem(-53px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:rem(250px);
        @include media-breakpoint-down(xs){
          width:100%;
        }
      }
      &-fraction {
        margin: rem(0 0 0 24px);
        display: flex;
        white-space: nowrap;
        color:cl(secondary);
        font-size: rem(16px);
        line-height: rem(26px);
        letter-spacing: rem(1px);
        strong {
          font-size: rem(24px);
          color:cl(primary);
        }
      }
      &-pagination {
        position: static;
        &-progressbar {
          background: #e0e0e0;
          &-fill {
            background: cl(primary);
          }
        }
      }
      &-button {
        &-prev,
        &-next {
          position: static;
          color:cl(secondary);
          margin: rem(0 5px);
          &:after {
            font-size: rem(35px);
          }
        }
      }
      &-wrapper {

      }
      &-slide {
        height: rem(700px);
        @include media-breakpoint-down(lg) {
          height: 40rem;
        }
        @include media-breakpoint-down(xs) {
          height: auto;
        }
      }
      &-image {
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        z-index: 1;
        @include media-breakpoint-down(xs) {
          position: relative;
          height: 13rem;
        }
        &:before {
          content: '';
          position: absolute;
          @extend .full;
          background: cl(black, 0.2);
        }
      }
      &-text {
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        color:white;
        text-align: center;
        padding: rem(0 300px 60px);
        z-index: 3;
        @include media-breakpoint-down(md) {
          text-align: right;
          padding-right: 2rem;
        }
        @include media-breakpoint-down(xs) {
          color:cl(secondary);
          position: static;
          padding: 1rem 0;
          text-align: center;
        }
        &:before {
          content: "";
          position: absolute;
          left:0;
          bottom: 0;
          width:100%;
          height: rem(270px);
          z-index: 2;
          background: linear-gradient(to top,  rgba(0,90,164,.9) 40%, rgba(0,90,164,0) 100%);
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        h3 {
          text-transform: uppercase;
          font-family: $fontTitle;
          font-weight: normal;
          font-size: rem(60px);
          line-height: rem(70px);
          letter-spacing: rem(2px);
          position: relative;
          z-index: 3;
          @include media-breakpoint-down(lg) {
            font-size: rem(40px);
            line-height: rem(50px);
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(30px);
            line-height: rem(40px);
            text-transform: none;
          }
        }
        .date {
          display: block;
          font-family: $fontTitle;
          font-size: rem(30px);
          letter-spacing: rem(2px);
          position: relative;
          z-index: 3;
          @include media-breakpoint-down(lg) {
            font-size: rem(20px);
          }
        }
        .btn {
          z-index: 3;
          @extend .pos-centerX;
          bottom: rem(-25px);
          &:hover {
            background: white;
          }
        }
      }
      &-ports {
        position: absolute;
        width:rem(300px);
        left:0;
        bottom: 0;
        color:white;
        padding: rem(0 10px 26px 28px);
        font-size: rem(15px);
        line-height: rem(18px);
        text-transform: uppercase;
        letter-spacing: rem(1px);
        z-index: 4;
        @include media-breakpoint-down(xs) {
          color:cl(text);
          position: static;
          padding: 1rem 0;
        }
        ul {
          margin: rem(20px 0 0);
          list-style: none;
          li {
            position: relative;
            padding: rem(0 0 0 17px);
            margin: rem(0 0 10px);
            &:before {
              content: "";
              position: absolute;
              left:0;
              top:rem(4px);
              width:rem(7px);
              height: rem(7px);
              border-radius: 50%;
              background: white;
              @include media-breakpoint-down(xs) {
                background: cl(text);
              }
            }
            &:after {
              content: "";
              position: absolute;
              left:rem(3px);
              top:rem(14px);
              width:1px;
              height: rem(16px);
              background: white;
              @include media-breakpoint-down(xs) {
                background: cl(text);
              }
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &-about {
    overflow: hidden;
    padding: rem(60px 40px 95px);
    font-size: rem(18px);
    line-height: rem(30px);
    @include media-breakpoint-down(xs) {
      padding: 3.3125rem 2.0rem 2.9375rem;
    }
    &--background {
      background: url(../img/cruise-bg.jpg) no-repeat 50% 100%;
      background-size: 100% auto;
      padding-bottom: rem(140px);
    }
    h3 {
      font-family: $fontTitle;
      font-weight: normal;
      color:cl(primary);
      font-size: rem(70px);
      line-height: rem(90px);
      margin: rem(0 0 40px);
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
        line-height: rem(50px);
      }
    }
    ul {
      list-style: none;
      li {
        position: relative;
        padding: rem(0 0 0 27px);
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(10px);
          width:rem(8px);
          height: rem(8px);
          border-radius: 50%;
          background: cl(secondary);
        }
      }
    }
    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
    &-text {
      width:50%;
      padding: rem(0 140px 0 120px);
      @include media-breakpoint-down(lg) {
        padding: rem(0 40px 0 0);
      }
      @include media-breakpoint-down(md) {
        width:100%;
        padding: 0;
      }
      a {
        color:cl(secondary);
      }
    }
    &-image {
      width:50%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      padding-top: 31%;
      @include media-breakpoint-down(md) {
        width:100%;
        margin: rem(0 0 30px);
        padding-top: 60%;
      }
    }
  }
  &-info {
    padding: rem(0 40px);
    font-size: rem(18px);
    line-height: rem(30px);
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 2.0rem;
    }
    &:before {
      content: "";
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      background: url(../img/shadow.png) no-repeat 100% 50%;
      background-size: 75% 100%;
      z-index: 2;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &-image {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      z-index: 1;
      @include media-breakpoint-down(md) {
        position: static;
        height: 0;
        padding-top: 42%;
        margin: rem(0 0 30px);
      }
    }
    a {
      color:cl(secondary);
    }
    &-logo {
      @extend .pos-centerY;
      width:rem(116px);
      height: auto;
      right:rem(22px);
      z-index: 3;
      @include media-breakpoint-down(lg) {
        width:3.5rem;
        right:1rem;
      }
    }
    &-text {
      width:50%;
      padding: rem(130px 270px 130px 0);
      position: relative;
      z-index: 3;
      @include media-breakpoint-down(lg) {
        padding: 3.5rem 2.5rem 3.5rem 0;
      }
      @include media-breakpoint-down(md) {
        width:100%;
        padding: 0;
      }
    }
  }
  &-insurance {
    text-align: center;
    font-size: rem(18px);
    line-height: rem(30px);
    padding: rem(30px 0 36px);
    color:white;
    background:cl(primary) url(../img/insurance-bg.jpg) no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    &-banner {
      display: block;
      margin: 0 auto;
      width:rem(300px);
      height: auto;
      @include media-breakpoint-up(xl) {
        right:rem(40px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      img {
        width:100%;
        height: auto;
      }
    }
    .title {
      color:white;
    }
    a {
      color:white;
    }
    &-text {
      padding: rem(60px 0 40px);
      position: relative;
      &:before {
        content: "";
        @extend .pos-centerX;
        top:0;
        width:rem(192px);
        height: rem(58px);
        background: url(../img/insurance-top.png) no-repeat 50% 50%;
        background-size: contain;
      }
      &:after {
        content: "";
        @extend .pos-centerX;
        bottom:0;
        width:rem(192px);
        height: rem(65px);
        background: url(../img/insurance-bottom.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }
    &-more {
      display: inline-block;
      position: relative;
      z-index: 3;
      color:white;
      text-transform: uppercase;
      font-size: rem(15px);
      letter-spacing: rem(1px);
      font-weight: 700;
    }
    p {
      margin: rem(0 0 10px);
    }
  }
  &-tips {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(100px 40px);
    font-size: rem(18px);
    line-height: rem(30px);
    color:white;
    position: relative;
    &--height {
      min-height: rem(620px);
    }
    @include media-breakpoint-down(xs) {
      padding: rem(50px 32px);
    }
    .title {
      color:white;
    }
    &-text {
      width:50%;
      padding: rem(0 200px 0 120px);
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
    &-image {
      position: absolute;
      bottom: 0;
      height: 88%;
      right:28%;
      width:auto;
      max-height: rem(406px);
      @include media-breakpoint-down(lg) {
        right: 14%;
        height: 62%;
      }
    }
  }
  &-articles {
    padding: rem(110px 160px 65px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      padding: rem(60px 40px 50px);
    }
    @include media-breakpoint-down(xs) {
      padding: rem(60px 32px 50px);
      flex-direction: column;
      gap:2rem;
    }
    a {
      color:cl(secondary);
    }
    &-col {
      width:45%;
      font-size: rem(18px);
      line-height: rem(30px);
      text-align: center;
      @include media-breakpoint-down(md) {
        width:48%;
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
    &-image {
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      padding-top: 34%;
      margin: rem(0 0 30px);
    }
    &-more {
      text-transform: uppercase;
      font-size: rem(15px);
      letter-spacing: rem(1px);
      font-weight: 700;
    }
  }
}