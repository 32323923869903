.static {
  font-size: rem(18px);
  line-height: rem(28px);
  color:cl(text);
  &-inner {
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
  }
  &-custom_list {
    ol {
      list-style-type: none;
      counter-reset: elementcounter;
      li {
        line-height: rem(25px);
        padding: rem(0 0 0 48px);
        margin: rem(0 0 15px);
        position: relative;
        &:before {
          position: absolute;
          left:0;
          top:0;
          content: counter(elementcounter);
          counter-increment: elementcounter;
          color:white;
          width:rem(25px);
          height: rem(25px);
          background: cl(primary);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rem(16px);
        }
      }
    }
  }
  p {
    margin: rem(0 0 20px);
  }
  h2 {
    font-family: $fontTitle;
    color:cl(secondary);
    font-size: rem(30px);
    line-height: rem(40px);
    margin: rem(0 0 25px);
  }
  h3 {
    font-family: $fontTitle;
    font-size: rem(26px);
    margin: rem(0 0 20px);
  }
  h4 {
    font-family: $fontTitle;
    font-size: rem(24px);
    margin: rem(0 0 20px);
  }
  h5 {
    font-family: $fontTitle;
    font-size: rem(22px);
    margin: rem(0 0 20px);
  }
  h6 {
    font-family: $fontTitle;
    font-size: rem(20px);
    margin: rem(0 0 20px);
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 20px);
  }
  .card {
    box-shadow:none;
    border-radius:0;
    background: white;
    position: relative;
    border: 0;
    margin: rem(0 0 10px);
    .card-header {
      background: none;
      border: 0;
      padding: 0;
      button {
        margin: rem(0 0 16px);
        font-size: rem(30px);
        line-height: rem(32px);
        font-family: $fontTitle;
        color:cl(secondary);
        font-weight: normal;
        width:100%;
        display: block;
        background: white;
        text-align: left;
        z-index: 2;
        border:0;
        background: none;
        border-bottom: 1px solid #e1e1e1;
        padding: rem(10px 24px 15px 0);
        position: relative;
        &:focus {
          outline: none;
          color:cl(primary);
          border-color:cl(secondary);
        }
        &.collapsed {
          border-bottom: 1px solid #e1e1e1;
          .arrow {
            transform: rotate(0);
            top:rem(6px);
          }
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        .arrow {
          position: absolute;
          right:0;
          top:rem(14px);
          transform: rotate(-180deg);
          transition: all .3s ease;
          svg {
            width:rem(18px);
            height: rem(18px);
            fill:cl(primary);
          }
        }
      }
    }
    .card-body {
      padding: rem(15px 0);
      font-size: rem(18px);
      line-height: rem(38px);
      //border: 1px solid cl(secondary);
      border-top:0;
      //box-shadow: rem(0 19px 40px cl(black, .16));
      p {
        margin: 0 0 1rem;
      }
      ul {
        list-style: none;
        li {
          position: relative;
          padding: rem(0 0 0 15px);
          &:before {
            content: "";
            position: absolute;
            left:0;
            top:rem(15px);
            width:rem(5px);
            height: rem(5px);
            background: cl(secondary);
            border-radius: 50%;
          }
        }
      }
    }
  }
}