/* banner start */
.banner {
  position: relative;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(md) {
      height: 46rem;
    }
    @include media-breakpoint-down(sm) {
      height: 38rem;
    }
    &:before {
      content: "";
      position: absolute;
      left:0;
      bottom: 0;
      width:100%;
      height: rem(270px);
      z-index: 2;
      background: linear-gradient(to top,  rgba(0,35,77,.9) 40%, rgba(0,35,77,0) 100%);
    }
  }
  &--inner {
    height: rem(500px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    @include media-breakpoint-down(md) {
      background-attachment: scroll;
      height: 25rem;
    }
    @include media-breakpoint-down(xs) {
      height: 15rem;
      margin: 5rem 0 0;
    }&:before {
       content: "";
       @extend .full;
       background: cl(black, .12);
     }
  }
  &--blur {

    &:before {
      content: "";
      @extend .full;
      background: cl(black, .1);
      backdrop-filter: blur(2px);
    }
  }
  &-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      //content: '';
      //position: absolute;
      //@extend .full;
      //background: cl(black, 0.5);
      //z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
        top: -20px;
        height: calc(100% + 20px);
      }
      @media #{$min-aspect169} {
        height: 400%;
        top: -150%;
      }
      @media #{$max-aspect169} {
        width: 400%;
        left: -150%;
      }
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    letter-spacing: rem(2px);
    font-family: $fontTitle;
    text-shadow:rem(0 0 13px cl(black,.36));
    padding: rem(0 0 25px);
    .txt {
      display: block;
      font-size: rem(30px);
      line-height: rem(40px);
      margin: rem(0 0 20px);
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
        line-height: rem(30px);
        margin: rem(0 0 15px);
      }
    }
    .ttl {
      display: block;
      font-size: rem(70px);
      line-height: rem(80px);
      margin: rem(0 0 10px);
      @include media-breakpoint-down(md) {
        font-size: rem(60px);
        line-height: rem(70px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(30px);
        line-height: rem(40px);
      }
    }
    .arrow {
      font-size: rem(20px);
    }
    p {
      margin: 0;
    }
  }
}
/* banner end */

