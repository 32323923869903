.blog {
  &-grid {
    padding: rem(0 160px 35px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(50px 38px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(md) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-item {
    &:hover {
      @include media-breakpoint-up(xl) {
        .blog-item-image {
          span {
            transform: scale(1.1);
          }
        }
        .blog-item-inner {
          border-color:cl(primary);
          box-shadow:none;
        }
        .blog-item-more {
          opacity: 1;
        }
      }
    }
    &-image {
      display: block;
      overflow: hidden;
      position: relative;
      //height: rem(200px);
      padding-top: 54.5%;
      span {
        display: block;
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: all .3s linear;
      }
    }
    &-inner {
      padding: rem(25px 23px 30px);
      position: relative;
      background: white;
      box-shadow: rem(0 7px 16px cl(black, .1));
      border:1px solid white;
      border-top:0;
      transition: all .2s linear;
      h2 {
        font-family: $fontTitle;
        font-size: rem(18px);
        line-height: rem(26px);
        margin: (0 0 10px);
        height: rem(52px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color:cl(secondary);
        a {
          color:cl(secondary);
          &:hover {
            text-decoration: none;
            color:cl(primary);
          }
        }
      }
    }
    &-date {
      font-family: $fontTitle;
      font-size: rem(16px);
      color:#a2a2a2;
      font-style: italic;
      margin: (0 0 15px);
      letter-spacing: rem(1px);
    }
    &-text {
      font-size: rem(15px);
      line-height: rem(25px);
      height: rem(125px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      color:cl(text);
      p {
        margin: 0;
      }
    }
    &-more {
      position: absolute;
      left:0;
      width:100%;
      bottom: rem(-20px);
      padding: rem(0 58px);
      transition: all .2s linear;
      @include media-breakpoint-up(xl) {
        opacity: 0;
      }
      .btn {
        line-height: rem(38px);
        &:hover {
          background: white;;
          color:cl(primary);
        }
      }
    }
  }
  &-sidebar {
    background: white;
    box-shadow: rem(0 7px 16px cl(black, .1));
    padding: rem(23px 20px 20px);
    h4 {
      color:cl(primary);
      font-size: rem(24px);
      font-weight: normal;
      margin: rem(0 0 20px);
      font-family: $fontTitle;
    }
    &-all {
      display: inline-block;
      text-transform: uppercase;
      font-size: rem(14px);
      font-weight: 700;
    }
  }
  &-search {
    position: relative;
    margin: rem(0 0 20px);
    &-txt {
      display: block;
      width:100%;
      font-family: $font;
      font-size: rem(15px);
      height: rem(44px);
      bordeR:1px solid #e4e4e4;
      padding: rem(0 40px 0 18px);
      color:cl(text);
      @include placeholder(#909090);
    }
    &-btn {
      position: absolute;
      top:0;
      right:0;
      height: rem(44px);
      width: rem(44px);
      background: none;
      border:0;
      font-size: rem(16px);
      color:#838383;
    }
  }
  &-list {
    display: flex;
    align-items: flex-start;
    margin: rem(0 0 20px);
    &-image {
      width:rem(155px);
      margin: rem(5px 30px 0 0);
      padding-top: 22.5%;
      flex-shrink: 0;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
    &-hold {
      h5 {
        font-family: $fontTitle;
        font-size: rem(16px);
        line-height: rem(26px);
        margin: (0 0 10px);
        height: rem(78px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color:cl(secondary);
        a {
          color:cl(secondary);
          &:hover {
            text-decoration: none;
            color:cl(primary);
          }
        }
      }
    }
    &-date {
      font-family: $fontTitle;
      font-size: rem(14px);
      color:#a2a2a2;
      font-style: italic;
      margin: (0 0 15px);
      letter-spacing: rem(1px);
    }
  }
  &-article {
    font-size: rem(18px);
    line-height: rem(30px);
    color:cl(text);
    @include media-breakpoint-up(xl) {
      padding-right: rem(20px);
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: rem(30px);
    }
    &-author {
      display: flex;
      align-items: center;
      font-size: rem(14px);
      line-height: rem(20px);
      color:#3c3c3c;
      margin: rem(0 0 35px);
      &-photo {
        flex-shrink: 0;
        width:rem(40px);
        height: rem(40px);
        border-radius: 50%;
        margin: rem(0 10px 0 0);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
      em {
        display: block;
        font-family: $fontTitle;
        font-size: rem(14px);
        color:#a2a2a2;
        font-style: italic;
        letter-spacing: rem(1px);
      }
    }
    h2 {
      font-family: $fontTitle;
      font-size: rem(30px);
      line-height: rem(50px);
      margin: (0 0 30px);
      color:cl(secondary);
    }
    h3 {
      font-size: rem(28px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
    }
    h4 {
      font-size: rem(26px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
    }
    h5 {
      font-size: rem(24px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
    }
    h6 {
      font-size: rem(22px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
    img {
      max-width:100%;
      height: auto;
      display: block;
      margin: 0 auto 2rem;
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top:1px solid #e1e1e1;
      padding: rem(30px 0 0);
      margin: rem(30px 0 0);
    }
  }
  &-share {
    display: flex;
    a {
      color:cl(secondary);
      margin: rem(0 0 0 25px);
    }
  }
}