.cruise {
  font-size: rem(18px);
  line-height: rem(30px);
  &-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: rem(58px 48px);
    padding: rem(30px 160px 60px);
    @include media-breakpoint-down(lg) {
      padding: rem(30px 32px 60px);
      gap: 2.5rem 2rem;
    }
    &-item {
      box-shadow: rem(0 19px 40px cl(black, .16));
      width:calc(33.3% - 3rem);
      position: relative;
      @include media-breakpoint-down(lg) {
        width:calc(33.3% - 2rem);
      }
      @include media-breakpoint-down(md) {
        width:calc(50% - 2rem);
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
      &:hover {
        .cruise-list-title {
          .date {
            @include media-breakpoint-up(md) {
              opacity: 0;
            }
          }
        }
        .cruise-list-mask {
          opacity: 1;
          transform: scale(1.0);
        }
      }
    }
    &-mask {
      position: absolute;
      left:0;
      top:0;
      right:0;
      bottom: 0;
      border:1px solid cl(secondary);
      background: rgb(0,41,101); /* Old browsers */
      background: radial-gradient(ellipse at center,  rgba(0,90,164,1) 0%, rgba(0,41,101,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      color:white;
      padding: rem(30px 30px 40px);
      font-size: rem(16px);
      line-height: rem(26px);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      //opacity: 0;
      transition: all .5s ease;
      transform: scale(0.0);
      z-index: 5;
      @include media-breakpoint-down(sm) {
        position: relative;
        top:auto;
        bottom: auto;
        transform: scale(1.0);
      }
      .text-scroll {
        max-height: 100%;
        .mCSB_inside>.mCSB_container {
          margin-right: 10px;
        }
        .mCSB_scrollTools {
          width:4px;
        }
      }
      .btn {
        width:rem(250px);
        @extend .pos-centerX;
        bottom: rem(-20px);
        font-size: rem(18px);
        line-height: rem(40px);
        border-radius: rem(20px);
        &:hover {
          background: cl(secondary);
        }
      }
    }
    &-image {
      padding-top: 67%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
    &-title {
      border:1px solid cl(secondary);
      border-top:0;
      background: white;
      padding: rem(10px 30px 20px);
      height: rem(110px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      h3 {
        text-transform: uppercase;
        font-family: $fontTitle;
        font-size: rem(26px);
        line-height: rem(28px);
        letter-spacing: rem(2px);
        color:cl(secondary);
        @include media-breakpoint-down(lg) {
          font-size: rem(22px);
          line-height: rem(24px);
        }
      }
      .date {
        @extend .pos-centerX;
        bottom: rem(-20px);
        font-family: $fontTitle;
        font-size: rem(18px);
        line-height: rem(40px);
        border-radius: rem(20px);
        background: cl(secondary);
        color:white;
        font-style: italic;
        letter-spacing: rem(1px);
        padding: rem(0 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all .5s ease;
        @include media-breakpoint-down(sm) {
          z-index: 6;
        }
      }
    }
  }
  &-tabs {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top:rem(122px);
    background: rgb(4,84,202);
    background: linear-gradient(to right,  rgba(4,84,202,1) 0%,rgba(0,119,240,1) 100%);
    padding: rem(26px 0);
    margin: rem(0 0 60px);
    z-index: 10;
    @include media-breakpoint-down(lg) {
      padding: rem(15px 0);
    }
    @include media-breakpoint-down(xs) {
      top: 6.25rem;
    }
    .nav {
      &-tabs {
        justify-content: center;
        margin: 0;
        border:0;
        gap:rem(5px);
        @include media-breakpoint-down(lg) {
          gap:rem(3px);
        }
      }
      &-item {
        margin: 0;
      }
      &-link {
        font-size: rem(16px);
        line-height: rem(18px);
        padding: rem(14px 22px);
        border-radius: rem(25px);
        border:0;
        color:white;
        font-weight: 700;
        &:hover,
        &.active {
          background: white;
          color:cl(secondary);
        }
        @include media-breakpoint-down(lg) {
          padding: rem(10px 15px);
          font-size: rem(15px);
        }
      }
    }
  }
  &-box {
    margin: rem(0 0 15px);
    font-size: rem(18px);
    line-height: rem(30px);
    img {
      max-width:100%;
      height: auto;
    }
    h2 {
      margin: rem(0 0 16px);
      font-size: rem(30px);
      line-height: rem(32px);
      font-family: $fontTitle;
      color:cl(secondary);
    }
    h3 {
      color:cl(primary);
      font-size: rem(24px);
      font-weight: 600;
      margin: rem(0 0 16px);
    }
    h4 {
      color:cl(primary);
      font-size: rem(22px);
      font-weight: 600;
      margin: rem(0 0 16px);
    }
    h5 {
      color:cl(primary);
      font-size: rem(20px);
      font-weight: 600;
      margin: rem(0 0 16px);
    }
    h6 {
      color:cl(primary);
      font-size: rem(18px);
      font-weight: 600;
      margin: rem(0 0 16px);
    }

    ul {
      list-style: none;
      li {
        position: relative;
        padding: rem(0 0 0 27px);
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(10px);
          width:rem(8px);
          height: rem(8px);
          border-radius: 50%;
          background: cl(secondary);
        }
      }
    }
    .suites-image {
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      padding-top: 56.25%;
      margin: rem(0 0 30px);
    }
    &.active {
      .cruise-box-content {
        height: auto;
      }
      .cruise-box-open {
        .arrow {
          top:rem(14px);
          transform: rotate(-180deg);
        }
      }
    }
    &-open {
      display: block;
      width:100%;
      text-align: left;
      border:0;
      background: none;
      border-bottom: 1px solid #e1e1e1;
      padding: rem(10px 24px 15px 0);
      position: relative;
      &:focus {
        outline: none;
        color:cl(primary);
        border-color:cl(secondary);
      }
      h2 {
        margin: 0;
      }
      .arrow {
        position: absolute;
        right:0;
        top:rem(6px);
        transition: all .3s linear;
        svg {
          width:rem(16px);
          height:rem(16px);
          fill: cl(primary);
        }
      }
    }
    &-content {
      overflow: hidden;
      height: 0;
      &-inner {
        padding: rem(35px 0 5px);
      }
    }
  }
  &-slider {
    &-hold {
      padding: rem(0 40px);
      width:70%;
      position: relative;
      margin: 2rem auto 2.5rem;
      @include media-breakpoint-down(xs) {
        width:100%;
        padding: rem(0 22px);
      }
    }
    &-prev,
    &-next {
      @extend .pos-centerY;
      left:0;
      background: none;
      border:0;
      font-size: rem(30px);
      color:cl(primary);
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
    }
    &-next {
      left:auto;
      right:0;
    }
    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      padding-top: 56.25%;
    }
  }
  &-gallery {
    padding: rem(20px 0 0);
    &-item {
      display: block;
      margin: rem(0 0 30px);
      img {
        width:100%;
        height: auto;
      }
    }
  }
  &-itinerary {
    .table {
      tr {
        &:nth-child(odd) {
          td {
            background: #f7f7f7;
            @include media-breakpoint-down(xs) {
              border-top:1px solid #fff;
            }
          }
        }
        @include media-breakpoint-down(xs) {
          display: block;
        }
        th {
          border:0;
          border-bottom: 2px solid cl(secondary);
          padding: rem(5px 10px);
          text-transform: uppercase;
          color:cl(secondary);
          font-size: rem(16px);
          line-height: rem(18px);
          font-weight: 700;
          &:first-child {
            padding-left: rem(37px);
          }
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        td {
          border:0;
          font-size: rem(18px);
          line-height: rem(20px);
          color:black;
          padding: rem(15px 10px);
          &:first-child {
            @include media-breakpoint-up(sm) {
              padding-left: rem(37px);
            }
          }
          @include media-breakpoint-down(xs) {
            display: block;
            padding-right: rem(5px);
            padding-left: 40%;
            width:100%;
            position: relative;
            border-top:1px solid #f7f7f7;
            font-size: rem(16px);
            min-height: rem(50px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:before {
              content: attr(data-label);
              position: absolute;
              left:0;
              top:0;
              width:40%;
              padding: rem(15px 5px 0 10px);
              color:cl(secondary);
              text-transform: uppercase;
              font-weight: 600;
              font-size: rem(14px);
              line-height: rem(20px);
            }
          }
          .date {
            background: url(../img/svg/calendar.svg) no-repeat 0 50%;
            padding: rem(0 0 0 30px);
            background-size: rem(15px auto);
          }
          .port {
            background: url(../img/svg/port-map.svg) no-repeat 0 50%;
            padding: rem(0 0 0 25px);
            background-size: rem(18px auto);
          }
        }
      }
    }
  }
  &-themes {
    .table {
      tr {
        &:nth-child(odd) {
          td {
            background: #f7f7f7;
            @include media-breakpoint-down(xs) {
              border-top:1px solid #fff;
            }
          }
        }
        @include media-breakpoint-down(xs) {
          display: block;
        }
        th {
          border:0;
          border-bottom: 2px solid cl(secondary);
          padding: rem(5px 20px);
          text-transform: uppercase;
          color:cl(secondary);
          font-size: rem(16px);
          line-height: rem(18px);
          font-weight: 700;
          &:first-child {
            padding-left: rem(37px);
            width:25%;
          }
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        td {
          border:0;
          font-size: rem(18px);
          line-height: rem(28px);
          color:black;
          padding: rem(20px);
          &:first-child {
            @include media-breakpoint-up(sm) {
              padding-left: rem(37px);
            }
          }
          @include media-breakpoint-down(xs) {
            display: block;
            width:100%;
            position: relative;
            border-top:1px solid #f7f7f7;
          }
          .date {
            background: url(../img/svg/calendar.svg) no-repeat 0 50%;
            padding: rem(0 0 0 30px);
            background-size: rem(15px auto);
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  &-book {
    padding: rem(20px 0);
    &-link {
      display: inline-block;
      vertical-align: top;
      text-transform: uppercase;
      font-weight: 700;
      font-size: rem(40px);
      line-height: rem(50px);
      color:black;
      margin: rem(0 0 20px);
    }
  }
}