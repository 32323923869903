.footer {
  background: #f59334 url(../img/footer-bg.png) no-repeat 50% 100%;
  background-size: 100% auto;
  padding: rem(26px 40px);
  color:cl(white);
  font-size: rem(16px);
  @media (max-width: 1700px) {
    padding-right: 5rem;
  }
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  @include media-breakpoint-down(sm) {
    padding: 2rem 15px;
  }
  &-logo {
    display: block;
    margin: 0;
    width:rem(260px);
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    line-height: rem(20px);
    @include media-breakpoint-down(md) {
      padding: rem(20px 0);
    }
    a {
      color:white;
      margin: rem(0 10px);
      &:hover {
        text-decoration: none;
        color:cl(secondary);
      }
      &:first-child {margin-left: 0;}
      &:last-child {margin-right: 0;}
    }
  }
  &-call {
    font-size: rem(20px);
    line-height: rem(26px);
    font-weight: 700;
    color:white;
    &:hover {
      text-decoration: none;
      color:cl(secondary);
    }
  }
  &-copy {
    display: block;
    margin: rem(16px 0 0);
    font-size: rem(13px);
  }
}

